import { defineComponent, type PropType, computed, type CSSProperties } from 'vue';
import './Table.sass';
import TableSkeleton from './TableSkeleton.vue';

export default defineComponent({
  name: 'TableRenderer',
  props: {
    headers: {
      type: Array as PropType<{ label: string; value: string; style?: object }[]>,
      required: true,
    },
    items: {
      type: Array as PropType<unknown[]>,
      required: true,
    },
    contained: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    layoutFixed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const headers = props.headers;
    const classes = computed(() => {
      return [
        props.contained ? 'rounded-xl border border-solid border-neutral-200' : '',
        'overflow-auto table-container-scroll',
        'relative',
      ].filter(Boolean);
    });

    const headerClasses = computed(() => {
      return ['text-xsm bg-neutral-100', 'sticky top-0 z-30'];
    });

    const rowClasses = computed(() => {
      return 'relative';
    });

    const headerDefaultClasses = 'font-normal py-2 px-4 text-left';

    return () => (
      <div>
        {props.loading && <TableSkeleton headers={headers} />}
        {!props.loading && (
          <div class={classes.value} style="max-height: calc(100vh - 300px); z-index: 0">
            <table class="w-full orderprotection" style={{ tableLayout: props.layoutFixed ? 'fixed' : 'auto' }}>
              <thead class={headerClasses.value}>
                <tr class={rowClasses.value}>
                  {headers.length > 0 &&
                    headers.map(header =>
                      ctx.slots.header ? (
                        ctx.slots.header({ header, defaults: headerDefaultClasses })
                      ) : (
                        <th key={header.value} class={headerDefaultClasses} style={header.style as CSSProperties}>
                          {header.label}
                        </th>
                      ),
                    )}
                </tr>
              </thead>

              <tbody>{props.items.map(item => (ctx.slots.item ? ctx.slots.item({ item }) : null))}</tbody>
            </table>
          </div>
        )}
        {ctx.slots.footer && <div class="flex w-full justify-end">{ctx.slots.footer()}</div>}
      </div>
    );
  },
});
